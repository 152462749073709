<template>
  <div style="padding-top:50px;width:90%">
    <div class="login-form login-signin w-100 pb-5">
      <div class="text-center mb-10 mb-lg-20">
        <h3 class="font-size-h1">{{ $t("GENERAL.THANKS") }}</h3>
        <p class="font-weight-semi-bold">
          We have sent you an email so you can change your password and start
          using Ynsitu.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MessageCreated",
};
</script>
